import { Container } from 'inversify-props';
import setupSharedModule from './shared';
import setupRegisterModule from './register';
import setupOnboardingModule from './onboarding';
import setupAuthenticationModule from './authentication';
import setupVerifyNip from './verify-nip/setup';
import setupRenewVerifyNip from './renew-verify-nip/setup';
import setupAgreements from './agreements/setup';
import setupInternetStatus from './internet-status/setup';
import setupGeolocation from './geolocation/setup';
import setupPermissions from './permissions/setup';
import setupCurrentUser from '@/modules/current-user/setup';
import setupGroup from '@/modules/group/setup';

// eslint-disable-next-line import/prefer-default-export
export function setupModules(container: Container) {
  setupSharedModule(container);
  setupRegisterModule(container);
  setupOnboardingModule(container);
  setupAuthenticationModule(container);
  setupVerifyNip(container);
  setupRenewVerifyNip(container);
  setupAgreements(container);
  setupInternetStatus(container);
  setupPermissions(container);
  setupGeolocation(container);
  setupCurrentUser(container);
  setupGroup(container);
}
