// Domain
import { CustomerExtraBankAccountRepository }
  from '../../../domain/repositories/customer-extra-bank-account-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerExtraBankAccountDummyRepository
implements CustomerExtraBankAccountRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}
