import fixtures from './fixtures.json';

// Domain
import {
  ProductRepository,
} from '../../../domain/repositories/product-repository';
import {
  ProductEntity,
} from '../../../domain/entities/product-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ProductDummyRepository implements ProductRepository {
  get(): Promise<Array<ProductEntity>> {
    return Promise.resolve(fixtures);
  }
}
