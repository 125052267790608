import TYPES from '@/types';

// APPLICATION
import UpdateCustomerDocumentStateService from '@/modules/onboarding/customer-document/application/services/file/update-customer-document-state-service';

// DOMAIN
import { CreateDocumentDto } from '@/modules/onboarding/documents/domain/dtos/create-document-dto';
import { DocumentEntity } from '@/modules/onboarding/documents/domain/entities/document-entity';
import { DocumentTypeEnum } from '@/modules/onboarding/documents/domain/enums/document-type-enum';
import { CreateDocumentRepository } from '@/modules/onboarding/documents/domain/repository/create-document-repository';
import { StateManager } from '@/modules/onboarding/documents/domain/state/state-manager';
import Command from '@/modules/shared/domain/services/command';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';

@Injectable()
export default class SendBankAccountCommandService extends Command<CreateDocumentDto> {
  @Inject(TYPES.CREATE_DOCUMENT_REPOSITORY)
  private readonly repository!: CreateDocumentRepository;

  @Inject(TYPES.ONBOARDING_DOCUMENTS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.UPDATE_CUSTOMER_DOCUMENT_STATE_SERVICE)
  private readonly update_customer_document_state_service!: UpdateCustomerDocumentStateService;

  internalExecute(document: CreateDocumentDto) {
    return new Promise((resolve, reject) => {
      this.repository.execute(document, DocumentTypeEnum.BANK_ACCOUNT)
        .then(() => {
          this.update_customer_document_state_service.update(document.id, document.file_data);
          const document_index = this.state_manager.state.items.findIndex((item) => item.document_type.name === 'bank_statement');

          if (document_index < 0) {
            const new_document: DocumentEntity = {
              customer_document_id: '',
              customer_id: '',
              document_type: {
                id: '',
                name: 'bank_statement',
                description: 'Bank Statement',
              },
              file: {
                id: '',
                name: document.name,
                config: null,
                storage_source: '',
              },
            };
            this.state_manager
              .patch({ items: [...this.state_manager.state.items, new_document], loaded: false });
          } else {
            const { items } = this.state_manager.state;
            items[document_index] = {
              ...items[document_index],
              file: {
                ...items[document_index].file,
                name: document.name,
              },
            };
            this.state_manager.patch({ items, loaded: false });
          }
          resolve();
        })
        .catch(reject);
    });
  }
}
