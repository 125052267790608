import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '@/modules/onboarding/catalogs/economic-activity/types';
import { StateManager } from '../../domain/state/state-manager';
import { EconomicActivityRepository } from '../../domain/repository/economic-activity-repository';
import { EconomicActivityEntity } from '../../domain/entities/economic-activity-entity';
import { sortEconomicActivities } from '../../domain/services/sort-economic-activities';

@Injectable()
class GetEconomicActivitiesQueryService extends Query<Promise<Array<EconomicActivityEntity>>> {
  @Inject(TYPES.ONBOARDING_CATALOGS_ECONOMIC_ACTIVITY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ECONOMIC_ACTIVITY_REPOSITORY)
  private readonly repository!: EconomicActivityRepository;

  internalExecute(): Promise<Array<EconomicActivityEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.items.length >= 1) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.findAll()
          .then((items) => {
            const economic_activities = sortEconomicActivities(items);
            this.state_manager.patch({ items: economic_activities });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}

export default GetEconomicActivitiesQueryService;
