import { Route, NavigationGuardNext } from 'vue-router';

// Application
import { VerifyCustomerDocumentBankStatementMagicLinkToken }
  from '@/modules/onboarding/customer-document/application/services/bank-statement';

// Domain
import { DocumentTypeIdEnum } from '@/modules/onboarding/documents/domain/enums/document-type-id-enum';

export default class UpdateBankStatementController {
  static beforeIndexEnter(to: Route, from: Route, next: NavigationGuardNext) {
    const { token } = to.query;

    if (!token) next({ name: 'PageNotFound' });

    const verify_customer_document_bank_statement_magic_link_token = (
      new VerifyCustomerDocumentBankStatementMagicLinkToken()
    );

    verify_customer_document_bank_statement_magic_link_token.execute(token as string)
      .then((data) => {
        if (
          data.document_type_id.includes(DocumentTypeIdEnum.BANK_STATEMENT_ID)
          || data.document_type_id.includes(DocumentTypeIdEnum.SECOND_BANK_STATEMENT_ID)
        ) next();
        else next({ name: 'PageNotFound' });
      })
      .catch(() => {
        next({ name: 'PageNotFound' });
      });
  }
}
