import TYPES from '@/types';

// Domain
import { CustomerDocumentRepository } from '@/modules/onboarding/customer-document/domain/repositories';
import { VerifyMagicLinkResponse } from '../../../domain/dtos';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

export default class VerifyCustomerProofOfAddressMagicLinkToken extends
  Query<Promise<VerifyMagicLinkResponse>, string> {
  @Inject(TYPES.ONBOARDING_CUSTOMER_DOCUMENT_REPOSITORY)
  private readonly customer_document_repository!: CustomerDocumentRepository;

  internalExecute(token: string): Promise<VerifyMagicLinkResponse> {
    return new Promise((resolve, reject) => {
      this.customer_document_repository.verify_magic_link('proof-of-address', token)
        .then((data) => {
          if (data.valid) {
            resolve(data);
          } else {
            reject(new Error('Invalid magic link token'));
          }
        })
        .catch(reject);
    });
  }
}
