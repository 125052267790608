








































import { Component, Vue, Watch } from 'vue-property-decorator';

import ExpiredSessionViewModel from '@/vue-app/view-models/components/authentication/expired-session-view-model';

@Component({ name: 'ExpiredSession' })
export default class ExpiredSession extends Vue {
  expired_session_view_model = Vue.observable(new ExpiredSessionViewModel());

  @Watch('expired_session_view_model.is_auth')
  async onAuthenticationStateIsAuthChange(new_is_auth: boolean) {
    if (new_is_auth) {
      await this.expired_session_view_model.close_session_when_token_expires_job.perform_later();
    } else {
      this.expired_session_view_model.is_open = true;
    }
  }
}
