import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '../../types';
import { SearchPersonRepository } from '../../domain/repository/search-person-repository';
import { StateManager } from '../../domain/state/state-manager';
import { PersonEntity } from '../../domain/entities/person-entity';

@Injectable()
export default class GetPersonQueryService extends Query<Promise<PersonEntity>> {
  @Inject(TYPES.ONBOARDING_PERSON_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.SEARCH_PERSON_REPOSITORY)
  private readonly person_repository!: SearchPersonRepository;

  internalExecute(): Promise<PersonEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.person_was_loaded) {
        resolve(this.state_manager.state.person_entity);
      } else {
        this.person_repository.get()
          .then((person) => {
            this.state_manager.patch({ person_entity: { ...person }, person_was_loaded: true });
            resolve(person);
          })
          .catch(reject);
      }
    });
  }
}
