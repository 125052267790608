import TYPES from '@/modules/shared/types';

// Domain
import {
  OnBoardingStepsRepository,
} from '../../domain/repository/on-boarding-steps-repository';
import {
  StepNewClientWasAddedDto,
} from '../../domain/dtos/step-new-client-was-added-dto';
import {
  RequestNewLinkToRegisterDto,
} from '../../domain/dtos/request-new-link-to-register-dto';
import {
  StepNewClientWasAddedEntity,
} from '../../domain/entities/step-new-client-was-added-entity';
import {
  CreateUserPasswordVerifyTokenEntity,
} from '../../domain/entities/create-user-password-verify-token-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class OnBoardingStepsHttpRepository implements OnBoardingStepsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly base_url = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/on-boarding-steps/`;

  search_step_new_client_was_added(payload: StepNewClientWasAddedDto):
    Promise<Array<StepNewClientWasAddedEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.base_url}step_new_client_was_added/`, 'POST', payload)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }

  verify_token(payload: StepNewClientWasAddedDto): Promise<CreateUserPasswordVerifyTokenEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.base_url}create-user-password-verify-token/`, 'POST', payload)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }

  request_new_link_to_register(payload: RequestNewLinkToRegisterDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.base_url}new-link-to-register/`, 'POST', payload)
        .then((response) => {
          response.json()
            .then(({ success, message }) => {
              if (success) {
                resolve();
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }
}
