import TYPES from '@/modules/shared/types';

// Domain
import { SearchInstitutionDto } from '../../domain/dto/search-institution-dto';
import { InstitutionPaginatedEntity } from '../../domain/entities/institution-paginated-entity';
import { InstitutionRepository } from '../../domain/repository/institution-repository';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class InstitutionHttpRepository implements InstitutionRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/institutions/`;

  search(search_dto: SearchInstitutionDto): Promise<InstitutionPaginatedEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}search/`, 'POST', search_dto)
        .then((response) => {
          response.json()
            .then(({
              data,
              success,
              message,
              pagination,
            }) => {
              if (success) {
                resolve({ items: data, pagination });
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
