import TYPES from '../../types';

// Domain
import { InstitutionRepository } from '../../domain/repository/institution-repository';
import { InstitutionPaginatedEntity } from '../../domain/entities/institution-paginated-entity';
import { SearchInstitutionDto } from '../../domain/dto/search-institution-dto';
import Query from '@/modules/shared/domain/services/query';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';

@Injectable()
export default class SearchInstitutionsQuery
  extends Query<Promise<InstitutionPaginatedEntity>, SearchInstitutionDto> {
  @Inject(TYPES.SIAP_INSTITUTION_REPOSITORY)
  private readonly repository!: InstitutionRepository;

  internalExecute(search_dto: SearchInstitutionDto): Promise<InstitutionPaginatedEntity> {
    return new Promise((resolve, reject) => {
      this.repository.search(search_dto)
        .then(({ items, pagination }) => {
          resolve({ items, pagination });
        })
        .catch(reject);
    });
  }
}
