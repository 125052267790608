export default {
  CREATE_DOCUMENT_REPOSITORY: Symbol.for('CREATE_DOCUMENT_REPOSITORY'),
  SEND_PROOF_OF_ADDRESS_COMMAND_SERVICE: Symbol.for('SEND_PROOF_OF_ADDRESS_COMMAND_SERVICE'),
  SEND_BANK_ACCOUNT_COMMAND_SERVICE: Symbol.for('SEND_BANK_ACCOUNT_COMMAND_SERVICE'),
  ONBOARDING_DOCUMENTS_STATE_MANAGER: Symbol.for('ONBOARDING_DOCUMENTS_STATE_MANAGER'),
  ONBOARDING_SEARCH_DOCUMENTS_REPOSITORY: Symbol.for('ONBOARDING_SEARCH_DOCUMENTS_REPOSITORY'),
  SEARCH_DOCUMENTS_QUERY: Symbol.for('SEARCH_DOCUMENTS_QUERY'),
  ON_BOARDING_UPLOAD_RFC_FILE_COMMAND: Symbol.for('ON_BOARDING_UPLOAD_RFC_FILE_COMMAND'),
  ON_BOARDING_UPLOAD_SECOND_BANK_STATEMENT_FILE_COMMAND: Symbol.for('ON_BOARDING_UPLOAD_SECOND_BANK_STATEMENT_FILE_COMMAND'),
  CREATE_ON_BOARDING_INITIAL_CONTRIBUTION_PROOF_FILE_COMMAND:
    Symbol.for('CREATE_ON_BOARDING_INITIAL_CONTRIBUTION_PROOF_FILE_COMMAND'),
};
