import TYPES from '../../types';

// Domain
import { LogoutRepository } from '../../domain/repositories/logout-repository';
import Injectable from '@modules/shared/domain/di/injectable';
import Inject from '@modules/shared/domain/di/inject';
import Command from '@modules/shared/domain/use-cases/command';

@Injectable()
export default class LogoutCommand extends Command {
  @Inject(TYPES.LOGOUT_REPOSITORY)
  private readonly logout_repository!: LogoutRepository;

  internalExecute(): Promise<void> {
    return this.logout_repository.logout();
  }
}
