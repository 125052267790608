import TYPES from '../../types';

// Domain
import { RequestNewLinkToRegisterDto } from '../../domain/dtos/request-new-link-to-register-dto';
import { OnBoardingStepsRepository } from '../../domain/repository/on-boarding-steps-repository';
import Command from '@/modules/shared/domain/use-cases/command';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class RequestNewLinkToRegisterCommand extends Command<RequestNewLinkToRegisterDto> {
  @Inject(TYPES.ON_BOARDING_STEPS_REPOSITORY)
  private readonly repository!: OnBoardingStepsRepository;

  internalExecute(payload: RequestNewLinkToRegisterDto): Promise<void> {
    return this.repository.request_new_link_to_register(payload);
  }
}
