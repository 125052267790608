import USER_TYPES from '@/modules/register/user/types';
import ECONOMIC_ACTIVITY_TYPES from '@/modules/onboarding/catalogs/economic-activity/types';
import NATIONALITY_TYPES from '@/modules/onboarding/catalogs/nationality/types';
import SHARED_TYPES from '@/modules/shared/types';
import IDENTITY_VERIFICATION_TYPES from '@/modules/onboarding/identity-verification/types';
import ONBOARDING_TYPES from '@/modules/onboarding/types';
import AUTH_TYPES from '@/modules/authentication/types';
import VERIFY_NIP_TYPES from '@/modules/verify-nip/types';
import AGREEMENTS_TYPES from '@/modules/agreements/types';
import RESEND_VERIFY_NIP_TYPES from '@/modules/renew-verify-nip/types';
import INTERNET_STATUS_TYPES from '@/modules/internet-status/types';
import GEOLOCATION_TYPES from '@/modules/geolocation/types';
import PERMISSION_TYPES from '@/modules/permissions/types';
import CURRENT_USER_TYPES from '@/modules/current-user/types';
import GROUP_TYPES from '@/modules/group/types';

export default {
  ...USER_TYPES,
  ...ECONOMIC_ACTIVITY_TYPES,
  ...NATIONALITY_TYPES,
  ...SHARED_TYPES,
  ...IDENTITY_VERIFICATION_TYPES,
  ...AUTH_TYPES,
  ...ONBOARDING_TYPES,
  ...VERIFY_NIP_TYPES,
  ...AGREEMENTS_TYPES,
  ...RESEND_VERIFY_NIP_TYPES,
  ...INTERNET_STATUS_TYPES,
  ...GEOLOCATION_TYPES,
  ...PERMISSION_TYPES,
  ...CURRENT_USER_TYPES,
  ...GROUP_TYPES,
  VUE: Symbol.for('VUE'),
  APPLICATION: Symbol.for('APPLICATION'),
};
