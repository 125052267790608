import TYPES from '../../types';

// Domain
import { CurrencyEntity } from '../../domain/entities/currency-entity';
import { CurrencyRepository } from '../../domain/repository/currency-repository';
import { StateManager } from '../../domain/state/state-manager';
import Query from '@/modules/shared/domain/use-cases/query';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetAllCurrenciesQuery extends Query<Promise<Array<CurrencyEntity>>> {
  @Inject(TYPES.CURRENCY_REPOSITORY)
  readonly repository!: CurrencyRepository;

  @Inject(TYPES.CURRENCY_STATE_MANAGER)
  readonly state_manager!: StateManager;

  internalExecute(): Promise<Array<CurrencyEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get_all()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
