import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { DocumentEntity } from '../../domain/entities/document-entity';
import { StateManager } from '../../domain/state/state-manager';
import { SearchDocumentsRepository } from '../../domain/repository/search-documents-repository';

@Injectable()
export default class SearchDocumentsQuery extends Query<Promise<Array<DocumentEntity>>> {
  @Inject(TYPES.ONBOARDING_DOCUMENTS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ONBOARDING_SEARCH_DOCUMENTS_REPOSITORY)
  private readonly repository!: SearchDocumentsRepository;

  internalExecute(): Promise<Array<DocumentEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository
          .execute()
          .then((documents) => {
            this.state_manager.patch({ items: documents, loaded: true });
            resolve(documents);
          })
          .catch(reject);
      }
    });
  }
}
