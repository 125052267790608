export default {
  AUTHENTICATION_DATA_REPOSITORY: Symbol.for('AUTHENTICATION_DATA_REPOSITORY'),
  SIGN_IN_COMMAND: Symbol.for('SIGN_IN_COMMAND'),
  AUTHENTICATION_JWT_REPOSITORY: Symbol.for('AUTHENTICATION_JWT_REPOSITORY'),
  AUTHENTICATION_STATE_MANAGER: Symbol.for('AUTHENTICATION_STATE_MANAGER'),
  GET_AUTHENTICATION_STATE_SERVICE: Symbol.for('GET_AUTHENTICATION_STATE_SERVICE'),
  CLOSE_SESSION_WHEN_TOKEN_EXPIRES_JOB: Symbol.for('CLOSE_SESSION_WHEN_TOKEN_EXPIRES_JOB'),
  PASSWORD_REPOSITORY: Symbol.for('PASSWORD_REPOSITORY'),
  VALIDATE_PASSWORD_RECOVERY_COMMAND: Symbol.for('VALIDATE_PASSWORD_RECOVERY_COMMAND'),
  RECOVER_PASSWORD_COMMAND: Symbol.for('RECOVER_PASSWORD_COMMAND'),
  VALIDATE_PASSWORD_QUERY: Symbol.for('VALIDATE_PASSWORD_QUERY'),
  PASSWORD_CHANGED_STATUS_SUBSCRIPTION: Symbol.for('PASSWORD_CHANGED_STATUS_SUBSCRIPTION'),
  LOGOUT_REPOSITORY: Symbol.for('LOGOUT_REPOSITORY'),
  LOGOUT_COMMAND: Symbol.for('LOGOUT_COMMAND'),
};
