import TYPES from '../../types';

// Application
import LogoutCommand from '@/modules/authentication/application/commands/logout-command';

// Domain
import { StateManager } from '../../domain/state/state-manager';
import Inject from '@modules/shared/domain/di/inject';
import Injectable from '@modules/shared/domain/di/injectable';

@Injectable()
export default class CloseSessionWhenTokenExpiresJob {
  @Inject(TYPES.AUTHENTICATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.LOGOUT_COMMAND)
  readonly logout_command!: LogoutCommand;

  private interval?: NodeJS.Timer;

  perform_later = async () => {
    if (!this.interval) {
      const expiration_datetime = sessionStorage.getItem('session_til');
      const authentication_token = sessionStorage.getItem('authorization');

      if (expiration_datetime && authentication_token) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const expiration_parsed_datetime = new Date(expiration_datetime!);
        // eslint-disable-next-line
        // @ts-ignore
        const minutes_til_expiration = expiration_parsed_datetime - new Date();

        if (minutes_til_expiration >= 0) {
          this.interval = setTimeout(async () => {
            await this.endSession();
          }, minutes_til_expiration);
        } else {
          await this.endSession();
        }
      } else if (authentication_token) {
        await this.endSession();
      }
    }
  }

  private endSession = async () => {
    await this.logout_command.execute();
    this.state_manager.patch({ is_auth: false });
    sessionStorage.clear();
  }
}
