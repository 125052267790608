import CATALOGS_TYPES from './catalogs/types';
import PHONE_TYPES from './phone/types';
import STATUS_TYPES from './status/types';
import ADDRESS_TYPES from './address/types';
import DOCUMENTS_TYPES from './documents/types';
import PERSON_TYPES from './person/types';
import CUSTOMER_EMAIL_TYPES from './customer-email/types';
import BENEFICIARY_TYPES from './beneficiary/types';
import CUSTOMER_AGREEMENT_TYPES from './customer-agreement/types';
import EXPECTED_TRANSACTIONALITY_TYPES from './expected-transactionality/types';
import BANK_ACCOUNT_NUMBER_TYPES from './bank-account-number/types';
import ON_BOARDING_TYPES from './on-boarding/types';
import CUSTOMER_GEOLOCATIONS_TYPES from './customer-geolocations/types';
import INTERVIEWS_TYPES from './interviews/types';
import EMAIL_TYPES from './email/types';
import CUSTOMER_DOCUMENT_TYPES from './customer-document/types';
import PROOF_OF_ADDRESS_TYPES from './proof-of-address/types';
import ON_BOARDING_STEPS_TYPES from './on-boarding-steps/types';
import CUSTOMER_EXTRA_BANK_ACCOUNT_TYPES from './customer-extra-bank-account/types';
import SIAP_INSTITUTIONS_TYPES from './institutions/types';

export default {
  ...CATALOGS_TYPES,
  ...PHONE_TYPES,
  ...STATUS_TYPES,
  ...ADDRESS_TYPES,
  ...DOCUMENTS_TYPES,
  ...PERSON_TYPES,
  ...CUSTOMER_EMAIL_TYPES,
  ...BENEFICIARY_TYPES,
  ...CUSTOMER_AGREEMENT_TYPES,
  ...EXPECTED_TRANSACTIONALITY_TYPES,
  ...BANK_ACCOUNT_NUMBER_TYPES,
  ...ON_BOARDING_TYPES,
  ...CUSTOMER_GEOLOCATIONS_TYPES,
  ...INTERVIEWS_TYPES,
  ...EMAIL_TYPES,
  ...CUSTOMER_DOCUMENT_TYPES,
  ...PROOF_OF_ADDRESS_TYPES,
  ...ON_BOARDING_STEPS_TYPES,
  ...CUSTOMER_EXTRA_BANK_ACCOUNT_TYPES,
  ...SIAP_INSTITUTIONS_TYPES,
};
