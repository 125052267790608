import TYPES from '../../types';

// Domain
import {
  ProductEntity,
} from '../../domain/entities/product-entity';
import { ProductRepository } from '../../domain/repositories/product-repository';
import { StateManager } from '../../domain/state/state-manager';
import Query from '@/modules/shared/domain/use-cases/query';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetProductsQuery extends Query<Promise<Array<ProductEntity>>> {
  @Inject(TYPES.PRODUCTS_REPOSITORY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.PRODUCTS_REPOSITORY)
  private readonly repository!: ProductRepository;

  internalExecute(): Promise<Array<ProductEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
