import signUpRoute from './sign-up';
import updateBankStatementRoute from './update-bank-statement';
import updateProofOfAddress from './update-proof-of-address';
import recoverPassword from '@/vue-app/router/unauthenticated/recover-password';
import userCreatePassword from './user-create-password';

export default [
  signUpRoute,
  updateBankStatementRoute,
  updateProofOfAddress,
  recoverPassword,
  userCreatePassword,
];
