import { Route, NavigationGuardNext } from 'vue-router';

// Application
import VerifyCustomerProofOfAddressMagicLinkToken from '@/modules/onboarding/customer-document/application/services/proof-of-address/verify-customer-proof-of-address-magic-link-token';

// Domain
import { DocumentTypeIdEnum } from '@/modules/onboarding/documents/domain/enums/document-type-id-enum';

export default class UpdateProofOfAddressController {
  static beforeIndexEnter(to: Route, from: Route, next: NavigationGuardNext) {
    const { token } = to.query;

    if (!token) next({ name: 'PageNotFound' });

    const verify_customer_document_bank_statement_magic_link_token = (
      new VerifyCustomerProofOfAddressMagicLinkToken()
    );

    verify_customer_document_bank_statement_magic_link_token.execute(token as string)
      .then((data) => {
        if (
          data.document_type_id.includes(DocumentTypeIdEnum.PROOF_OF_ADDRESS_ID)
          || data.document_type_id.includes(DocumentTypeIdEnum.PROOF_OF_FISCAL_ADDRESS_ID)
        ) next();
        else next({ name: 'PageNotFound' });
      })
      .catch(() => {
        next({ name: 'PageNotFound' });
      });
  }
}
