import TYPES from '../../types';

// Domain
import { CreateDocumentDto } from '../../domain/dtos/create-document-dto';
import { DocumentTypeEnum } from '../../domain/enums/document-type-enum';
import {
  CreateDocumentRepository,
} from '../../domain/repository/create-document-repository';
import { DocumentEntity } from '../../domain/entities/document-entity';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Command from '@/modules/shared/domain/services/command';

@Injectable()
class CreateOnBoardingInitialContributionProofFileCommand extends Command<CreateDocumentDto> {
  @Inject(TYPES.CREATE_DOCUMENT_REPOSITORY)
  private readonly create_document_repository!: CreateDocumentRepository;

  @Inject(TYPES.ONBOARDING_DOCUMENTS_STATE_MANAGER)
  private readonly on_boarding_documents_state_manager!: StateManager;

  internalExecute(document: CreateDocumentDto) {
    return new Promise((resolve, reject) => {
      this.create_document_repository.execute(document, DocumentTypeEnum.INITIAL_CONTRIBUTION_PROOF)
        .then(() => {
          const document_index = this.on_boarding_documents_state_manager.state.items.findIndex(
            (item) => item.document_type.name === 'initial_contribution_proof',
          );

          if (document_index < 0) {
            const new_document: DocumentEntity = {
              customer_document_id: '',
              customer_id: '',
              document_type: {
                id: '',
                name: 'initial_contribution_proof',
                description: 'Initial contribution proof',
              },
              file: {
                id: '',
                name: document.name,
                config: null,
                storage_source: '',
              },
            };
            this.on_boarding_documents_state_manager.patch({
              items: [
                ...this.on_boarding_documents_state_manager.state.items,
                new_document,
              ],
              loaded: false,
            });
          } else {
            const { items } = this.on_boarding_documents_state_manager.state;
            items[document_index] = {
              ...items[document_index],
              file: {
                ...items[document_index].file,
                name: document.name,
              },
            };
            this.on_boarding_documents_state_manager.patch({ items, loaded: false });
          }
          resolve();
        })
        .catch(reject);
    });
  }
}

export default CreateOnBoardingInitialContributionProofFileCommand;
