import TYPES from '../../types';

// Domain
import { GroupRepository } from '../../domain/repository/group-repository';
import { CustomerGroupDto } from '../../domain/dtos/customer-group-dto';
import { GroupEntity } from '../../domain/entities/group-entity';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';

@Injectable()
export default class GetCustomerGroupIdQuery extends Query<Promise<GroupEntity>> {
  @Inject(TYPES.GROUP_REPOSITORY)
  private readonly group_repository!: GroupRepository;

  internalExecute(payload: CustomerGroupDto): Promise<GroupEntity> {
    return new Promise((resolve, reject) => {
      this.group_repository.get_customer_group_id(payload)
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
