import fixtures from './fixtures.json';

// Domain
import { InstitutionRepository } from '../../../domain/repository/institution-repository';
import { SearchInstitutionDto } from '../../../domain/dto/search-institution-dto';
import { InstitutionPaginatedEntity } from '../../../domain/entities/institution-paginated-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InstitutionDummyRepository implements InstitutionRepository {
  search(search_dto: SearchInstitutionDto): Promise<InstitutionPaginatedEntity> {
    return Promise.resolve({
      items: fixtures,
      pagination: {
        next_page: search_dto.pagination_spec.page + 1,
        page: search_dto.pagination_spec.page,
        page_size: search_dto.pagination_spec.page_size,
        previous_page: search_dto.pagination_spec.page - 1,
        total_items: fixtures.length,
        total_pages: Math.ceil(fixtures.length / search_dto.pagination_spec.page_size),
      },
    });
  }
}
