import TYPES from '@/types';

// Domain
import { LogoutRepository } from '../../../domain/repositories/logout-repository';
import { HttpConnector } from '@/modules/shared/domain/connectors/http';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class LogoutHttpRepository implements LogoutRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/auth/logout/`;

  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', {})
        .then((response) => {
          response.json().then(({ success, message }) => {
            if (success) {
              resolve();
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
