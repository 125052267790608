import { Container } from 'inversify-props';
import TYPES from './types';

// INFRASTRUCTURE
import CreateDocumentDummyRepository
  from './infrastructure/repository/create-document-repository/create-document-dummy-repository';
import CreateDocumentHttpRepository
  from './infrastructure/repository/create-document-repository/create-document-http-repository';
import SearchDocumentsDummyRepository
  from './infrastructure/repository/search-documents-repository/search-documents-dummy-repository';
import SearchDocumentsHttpRepository
  from './infrastructure/repository/search-documents-repository/search-documents-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// APPLICATION
import SearchDocumentsQuery from './application/queries/search-documents-query';
import {
  SendProofOfAddressCommandService,
  SendBankAccountCommandService,
  UploadRfcFileCommand,
  CreateOnBoardingInitialContributionProofFileCommand,
  UploadSecondBankStatementFileCommand,
} from './application/commands';

// DOMAIN
import { CreateDocumentRepository } from './domain/repository/create-document-repository';
import { SearchDocumentsRepository } from './domain/repository/search-documents-repository';
import { StateManager } from './domain/state/state-manager';

export default function setupDocuments(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.ONBOARDING_DOCUMENTS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CreateDocumentRepository>(TYPES.CREATE_DOCUMENT_REPOSITORY)
    .to(
      with_dummies
        ? CreateDocumentDummyRepository
        : CreateDocumentHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SendProofOfAddressCommandService>(TYPES.SEND_PROOF_OF_ADDRESS_COMMAND_SERVICE)
    .to(SendProofOfAddressCommandService)
    .inSingletonScope();

  container
    .bind<SendBankAccountCommandService>(TYPES.SEND_BANK_ACCOUNT_COMMAND_SERVICE)
    .to(SendBankAccountCommandService)
    .inSingletonScope();

  container
    .bind<SearchDocumentsRepository>(TYPES.ONBOARDING_SEARCH_DOCUMENTS_REPOSITORY)
    .to(
      with_dummies
        ? SearchDocumentsDummyRepository
        : SearchDocumentsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchDocumentsQuery>(TYPES.SEARCH_DOCUMENTS_QUERY)
    .to(SearchDocumentsQuery)
    .inSingletonScope();

  container
    .bind<UploadRfcFileCommand>(TYPES.ON_BOARDING_UPLOAD_RFC_FILE_COMMAND)
    .to(UploadRfcFileCommand)
    .inSingletonScope();

  container
    .bind<CreateOnBoardingInitialContributionProofFileCommand>(
      TYPES.CREATE_ON_BOARDING_INITIAL_CONTRIBUTION_PROOF_FILE_COMMAND,
    ).to(CreateOnBoardingInitialContributionProofFileCommand)
    .inSingletonScope();

  container
    .bind<UploadSecondBankStatementFileCommand>(
      TYPES.ON_BOARDING_UPLOAD_SECOND_BANK_STATEMENT_FILE_COMMAND,
    ).to(UploadSecondBankStatementFileCommand)
    .inSingletonScope();
}
