import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '@/modules/onboarding/catalogs/relationship/types';
import { RelationshipRepository } from '../../domain/repository/relationship-repository';
import { StateManager } from '../../domain/state/state-manager';
import { RelationshipEntity } from '../../domain/entities/relationship-entity';

@Injectable()
export default class GetRelationshipsQueryService extends
  Query<Promise<Array<RelationshipEntity>>> {
  @Inject(TYPES.ONBOARDING_CATALOGS_RELATIONSHIP_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.RELATIONSHIP_REPOSITORY)
  private readonly repository!: RelationshipRepository;

  internalExecute(): Promise<Array<RelationshipEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.items.length < 1) {
        this.repository.findAll()
          .then((items) => {
            this.state_manager.patch({ items });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
