import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import CurrencyDummyRepository from './infrastructure/repositories/currency-dummy-repository/currency-dummy-repository';
import CurrencyHttpRepository from './infrastructure/repositories/currency-http-repository';

// Application
import GetAllCurrenciesQuery from './application/queries/get-all-currencies-query';

// Domain
import { CurrencyRepository } from './domain/repository/currency-repository';
import { StateManager } from './domain/state/state-manager';

export default function setupCurrency(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CurrencyRepository>(TYPES.CURRENCY_REPOSITORY)
    .to(
      with_dummies
        ? CurrencyDummyRepository
        : CurrencyHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetAllCurrenciesQuery>(TYPES.GET_ALL_CURRENCIES_QUERY)
    .to(GetAllCurrenciesQuery)
    .inSingletonScope();

  container
    .bind<StateManager>(TYPES.CURRENCY_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();
}
