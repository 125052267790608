import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import GroupDummyRepository
  from './infrastructure/repositories/group-dummy-repository/group-dummy-repository';
import GroupHttpRepository
  from './infrastructure/repositories/group-http-repository';

// Application
import GetCustomerGroupIdQuery
  from './application/queries/get-customer-group-id-query';

// Domain
import { GroupRepository } from './domain/repository/group-repository';

export default function setupGroup(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<GroupRepository>(TYPES.GROUP_REPOSITORY)
    .to(
      withDummies
        ? GroupDummyRepository
        : GroupHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetCustomerGroupIdQuery>(TYPES.GET_CUSTOMER_GROUP_ID_QUERY)
    .to(GetCustomerGroupIdQuery)
    .inSingletonScope();
}
