import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CustomerExtraBankAccountDummyRepository
  from './infrastructure/repositories/customer-extra-bank-account-dummy-repository/customer-extra-bank-account-dummy-repository';
import CustomerExtraBankAccountHttpRepository
  from './infrastructure/repositories/customer-extra-bank-account-http-repository';

// Application
import CreateCustomerExtraBankAccountCommand from './application/commands/create-customer-extra-bank-account-command';

// Domain
import { CustomerExtraBankAccountRepository } from './domain/repositories/customer-extra-bank-account-repository';

export default function setupCustomerExtraBankAccount(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomerExtraBankAccountRepository>(TYPES
      .ONBOARDING_CUSTOMER_EXTRA_BANK_ACCOUNT_REPOSITORY)
    .to(
      withDummies
        ? CustomerExtraBankAccountDummyRepository
        : CustomerExtraBankAccountHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateCustomerExtraBankAccountCommand>(TYPES
      .ONBOARDING_CREATE_CUSTOMER_EXTRA_BANK_ACCOUNT_COMMAND)
    .to(CreateCustomerExtraBankAccountCommand)
    .inSingletonScope();
}
