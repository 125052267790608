import TYPES from '../../types';

// Domain
import { CustomerExtraBankAccountDto }
  from '../../domain/dtos/customer-extra-bank-account-dto';
import { CustomerExtraBankAccountRepository }
  from '../../domain/repositories/customer-extra-bank-account-repository';
import Command from '@/modules/shared/domain/use-cases/command';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class CreateCustomerExtraBankAccountCommand extends
  Command<CustomerExtraBankAccountDto> {
  @Inject(TYPES.ONBOARDING_CUSTOMER_EXTRA_BANK_ACCOUNT_REPOSITORY)
  private readonly repository!: CustomerExtraBankAccountRepository;

  internalExecute(extra_bank_account_dto: CustomerExtraBankAccountDto): Promise<void> {
    return this.repository.create(extra_bank_account_dto);
  }
}
