import TYPES from '../../../../../types';
import Inject from '@/modules/shared/domain/di/inject';

// Application
import OnboardingCreateCustomerAgreementCommand
  from '@/modules/onboarding/customer-agreement/application/commands/onboarding-create-customer-agreement-command';
import CreateBankAccountNumberCommand
  from '@/modules/onboarding/bank-account-number/application/commands/create-bank-account-number-command';
import CreateCustomerExtraBankAccountCommand
  from '@/modules/onboarding/customer-extra-bank-account/application/commands/create-customer-extra-bank-account-command';
import SaveOnboardingSectionServiceBase
  from './save-onboarding-section-service-base';

// Domain
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';
import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';
import { PromiseDto } from '@/modules/onboarding/status/domain/dtos/promise-dto';

export default class SaveBankInformationService extends SaveOnboardingSectionServiceBase {
  @Inject(TYPES.ONBOARDING_CREATE_CUSTOMER_AGREEMENT_COMMAND)
  // eslint-disable-next-line max-len
  private readonly onboardingCreateCustomerAgreementCommand!: OnboardingCreateCustomerAgreementCommand;

  @Inject(TYPES.ONBOARDING_CREATE_BANK_ACCOUNT_NUMBER_COMMAND)
  private readonly onboardingCreateBankAccountNumberCommand!: CreateBankAccountNumberCommand;

  @Inject(TYPES.ONBOARDING_CREATE_CUSTOMER_EXTRA_BANK_ACCOUNT_COMMAND)
  private readonly onboarding_create_customer_extra_bank_account_command!:
    CreateCustomerExtraBankAccountCommand;

  async execute(): SaveSectionResponseDto {
    const bank_information_step = await this.get_on_boarding_step_query.execute('bank_information');

    const base_promises: Array<PromiseDto> = [
      {
        id: 'onboarding_create_customer_agreement_command-0',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: bank_information_step.payload.agreements[0],
      },
      {
        id: 'onboarding_create_customer_agreement_command-1',
        command: this.onboardingCreateCustomerAgreementCommand,
        data: bank_information_step.payload.agreements[1],
      },
      {
        id: 'onboarding_create_bank_account_number_command',
        command: this.onboardingCreateBankAccountNumberCommand,
        data: {
          number: bank_information_step.payload.bank_account,
          bank_name: bank_information_step.payload.financial_institution,
          bank_id: null,
          siap_institution_id: bank_information_step.payload.siap_institution_id,
        },
      },
    ];

    if (bank_information_step.payload.want_to_add_another_bank_account) {
      base_promises.push(
        {
          id: 'onboarding_create_customer_extra_bank_account_command',
          command: this.onboarding_create_customer_extra_bank_account_command,
          data: {
            type_of_bank_account: bank_information_step.payload.extra_bank_account
              .type_of_bank_account,
            bank_statement_file_id: bank_information_step.payload.extra_bank_account
              .bank_statement_file_id,
            national_siap_institution_id: bank_information_step.payload.extra_bank_account
              .siap_institution_id || null,
            national_account_number: bank_information_step.payload.extra_bank_account.bank_account,
            national_bank_name: bank_information_step.payload.extra_bank_account
              .financial_institution,
            foreign_bank_name: bank_information_step.payload.extra_bank_account
              .foreign_account.siap_institution_name,
            foreign_siap_institution_id: bank_information_step.payload.extra_bank_account
              .foreign_account.siap_institution_id || null,
            foreign_id_cat_pais: bank_information_step.payload.extra_bank_account
              .foreign_account.id_cat_pais,
            foreign_account_or_iban: bank_information_step.payload.extra_bank_account
              .foreign_account.account_or_iban,
            foreign_aba: bank_information_step.payload.extra_bank_account.foreign_account.aba,
            foreign_swift: bank_information_step.payload.extra_bank_account.foreign_account.swift,
            foreign_for_further_credit_to: bank_information_step.payload.extra_bank_account
              .foreign_account.for_further_credit_to,
            foreign_currency_id: bank_information_step.payload.extra_bank_account
              .foreign_account.currency_id,
            foreign_currency_description: bank_information_step.payload.extra_bank_account
              .foreign_account.currency_description,
          },
        },
      );
    }

    const promises = (await this.filteredPromises(base_promises, 'bank_information')).map(
      (item) => item.command.execute(item.data),
    );

    return new Promise((resolve) => {
      Promise.allSettled(promises)
        .then((results) => {
          const rejected_promises: Array<StoredPromiseDto> = [];

          results.forEach((item, index) => {
            if (item.status === 'rejected') {
              rejected_promises.push({
                id: base_promises[index].id,
                error: item.reason.message.toLowerCase().split(' ').join('_'),
              });
            }
          });

          resolve({ bank_information: rejected_promises });
        });
    });
  }
}
