import search_step_new_client_was_added_fixtures
  from './search-step-new-client-was-added-fixtures.json';
import create_user_password_verify_token_fixtures
  from './create-user-password-verify-token-fixtures.json';

// Domain
import {
  OnBoardingStepsRepository,
} from '../../../domain/repository/on-boarding-steps-repository';
import {
  StepNewClientWasAddedDto,
} from '../../../domain/dtos/step-new-client-was-added-dto';
import {
  RequestNewLinkToRegisterDto,
} from '../../../domain/dtos/request-new-link-to-register-dto';
import {
  StepNewClientWasAddedEntity,
} from '../../../domain/entities/step-new-client-was-added-entity';
import {
  CreateUserPasswordVerifyTokenEntity,
} from '../../../domain/entities/create-user-password-verify-token-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class OnBoardingStepsDummyRepository implements OnBoardingStepsRepository {
  search_step_new_client_was_added(payload: StepNewClientWasAddedDto):
    Promise<Array<StepNewClientWasAddedEntity>> {
    return Promise.resolve(search_step_new_client_was_added_fixtures);
  }

  verify_token(payload: StepNewClientWasAddedDto): Promise<CreateUserPasswordVerifyTokenEntity> {
    return Promise.resolve(create_user_password_verify_token_fixtures);
  }

  request_new_link_to_register(payload: RequestNewLinkToRegisterDto): Promise<void> {
    return Promise.resolve();
  }
}
