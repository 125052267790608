import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import OnBoardingStepsDummyRepository
  from './infrastructure/repositories/on-boarding-steps-dummy-repository/on-boarding-steps-dummy-repository';
import OnBoardingStepsHttpRepository
  from './infrastructure/repositories/on-boarding-steps-http-repository';

// Application
import SearchStepNewClientWasAddedQuery
  from './application/queries/search-step-new-client-was-added-query';
import CreateUserPasswordVerifyTokenQuery
  from './application/queries/create-user-password-verify-token-query';
import RequestNewLinkToRegisterCommand
  from './application/commands/request-new-link-to-register-command';

// Domain
import { OnBoardingStepsRepository } from './domain/repository/on-boarding-steps-repository';

export default function setupOnboardingSteps(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<OnBoardingStepsRepository>(TYPES.ON_BOARDING_STEPS_REPOSITORY)
    .to(
      withDummies
        ? OnBoardingStepsDummyRepository
        : OnBoardingStepsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchStepNewClientWasAddedQuery>(TYPES.SEARCH_STEP_NEW_CLIENT_WAS_ADDED_QUERY)
    .to(SearchStepNewClientWasAddedQuery)
    .inSingletonScope();

  container
    .bind<CreateUserPasswordVerifyTokenQuery>(TYPES.CREATE_USER_PASSWORD_VERIFY_TOKEN_QUERY)
    .to(CreateUserPasswordVerifyTokenQuery)
    .inSingletonScope();

  container
    .bind<RequestNewLinkToRegisterCommand>(TYPES.REQUEST_NEW_LINK_TO_REGISTER_COMMAND)
    .to(RequestNewLinkToRegisterCommand)
    .inSingletonScope();
}
