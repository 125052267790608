import { PersonEntity } from '../entities/person-entity';

export default class State {
  person_was_loaded = false;

  person_entity: PersonEntity = {
    curp: '',
    updated_at: '',
    marital_status: {
      name: '',
    },
    elector_key: '',
    last_name: '',
    id: '',
    age: 0,
    created_at: '',
    date_of_birth: '',
    gender: {
      name: '',
    },
    second_last_name: '',
    nationality: {
      id: 0,
      name: '',
    },
    name: '',
    rfc: '',
  }
}
