import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InstitutionDummyRepository
  from './infrastructure/repositories/institution-dummy-repository/institution-dummy-repository';
import InstitutionHttpRepository
  from './infrastructure/repositories/institution-http-repository';

// Application
import SearchInstitutionsQuery from './application/queries/search-institutions-query';

// Domain
import { InstitutionRepository } from './domain/repository/institution-repository';

export default function setupSIAPInstitutions(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InstitutionRepository>(TYPES.SIAP_INSTITUTION_REPOSITORY)
    .to(
      withDummies
        ? InstitutionDummyRepository
        : InstitutionHttpRepository,
    ).inSingletonScope();

  container
    .bind<SearchInstitutionsQuery>(TYPES.SEARCH_SIAP_INSTITUTIONS_QUERY)
    .to(SearchInstitutionsQuery)
    .inSingletonScope();
}
