import TYPES from '../../types';

// Domain
import { OnBoardingStepsRepository } from '../../domain/repository/on-boarding-steps-repository';
import { StepNewClientWasAddedDto } from '../../domain/dtos/step-new-client-was-added-dto';
import { CreateUserPasswordVerifyTokenEntity } from '../../domain/entities/create-user-password-verify-token-entity';
import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';

@Injectable()
export default class CreateUserPasswordVerifyTokenQuery extends
  Query<Promise<CreateUserPasswordVerifyTokenEntity>> {
  @Inject(TYPES.ON_BOARDING_STEPS_REPOSITORY)
  private readonly on_boarding_steps_repository!: OnBoardingStepsRepository;

  internalExecute(payload: StepNewClientWasAddedDto): Promise<CreateUserPasswordVerifyTokenEntity> {
    return new Promise((resolve, reject) => {
      this.on_boarding_steps_repository.verify_token(payload)
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
