export default {
  ONBOARDING_CUSTOMER_DOCUMENT_REPOSITORY:
    Symbol.for('ONBOARDING_CUSTOMER_DOCUMENT_REPOSITORY'),
  ONBOARDING_UPDATE_CUSTOMER_DOCUMENT_FILE_COMMAND:
    Symbol.for('ONBOARDING_UPDATE_CUSTOMER_DOCUMENT_FILE_COMMAND'),
  ONBOARDING_CUSTOMER_DOCUMENT_STATE_MANAGER: Symbol.for('ONBOARDING_CUSTOMER_DOCUMENT_STATE_MANAGER'),
  GET_CUSTOMER_BASE64_DOCUMENT_QUERY: Symbol.for('GET_CUSTOMER_BASE64_DOCUMENT_QUERY'),
  UPDATE_CUSTOMER_DOCUMENT_STATE_SERVICE: Symbol.for('UPDATE_CUSTOMER_DOCUMENT_STATE_SERVICE'),
  VERIFY_CUSTOMER_DOCUMENT_MAGIC_LINK_TOKEN: Symbol.for('VERIFY_CUSTOMER_DOCUMENT_MAGIC_LINK_TOKEN'),
};
