import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import ProductDummyRepository
  from './infrastructure/repository/product-dummy-repository/product-dummy-repository';
import ProductHttpRepository
  from './infrastructure/repository/product-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import GetProductsQuery from './application/queries/get-products-query';

// Domain
import { ProductRepository } from './domain/repositories/product-repository';
import { StateManager } from './domain/state/state-manager';

export default function setupProduct(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.PRODUCTS_REPOSITORY_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<ProductRepository>(TYPES.PRODUCTS_REPOSITORY)
    .to(
      with_dummies
        ? ProductDummyRepository
        : ProductHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetProductsQuery>(TYPES.GET_PRODUCTS_QUERY)
    .to(GetProductsQuery)
    .inSingletonScope();
}
