import fixtures from './fixtures.json';

// Domain
import { CurrencyRepository } from '../../../domain/repository/currency-repository';
import { CurrencyEntity } from '../../../domain/entities/currency-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CurrencyDummyRepository implements CurrencyRepository {
  get_all(): Promise<Array<CurrencyEntity>> {
    return Promise.resolve(fixtures);
  }
}
