import Injectable from '@/modules/shared/domain/dependency_injection/injectable';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import Query from '@/modules/shared/domain/services/query';
import TYPES from '@/modules/onboarding/catalogs/profession/types';
import { ProfessionRepository } from '../../domain/repository/profession-repository';
import { StateManager } from '../../domain/state/state-manager';
import { ProfessionEntity } from '../../domain/entities/profession-entity';

@Injectable()
export default class GetProfessionsQueryService extends Query<Promise<Array<ProfessionEntity>>> {
  @Inject(TYPES.ONBOARDING_CATALOGS_PROFESSION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.PROFESSION_REPOSITORY)
  private readonly repository!: ProfessionRepository;

  internalExecute(): Promise<Array<ProfessionEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.items.length >= 1) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.findAll()
          .then((items) => {
            this.state_manager.patch({ items });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
