import documentFixtures from './document-fixtures.json';
import verify_magic_link_fixtures from './verify_magic_link_fixtures.json';

// Domain
import { CustomerDocumentRepository } from '../../domain/repositories';
import { VerifyMagicLinkResponse } from '../../domain/dtos';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class FakeCustomerDocumentRepository implements CustomerDocumentRepository {
  verify_magic_link(document_type: string, token: string): Promise<VerifyMagicLinkResponse> {
    return Promise.resolve(verify_magic_link_fixtures);
  }

  updateFile(): Promise<void> {
    return Promise.resolve();
  }

  downloadBase64CustomerDocument(): Promise<string> {
    return Promise.resolve(documentFixtures.file);
  }
}
