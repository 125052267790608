import fixtures from './fixtures.json';

// Domain
import {
  GroupRepository,
} from '../../../domain/repository/group-repository';
import {
  CustomerGroupDto,
} from '../../../domain/dtos/customer-group-dto';
import {
  GroupEntity,
} from '../../../domain/entities/group-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class GroupDummyRepository implements GroupRepository {
  get_customer_group_id(payload: CustomerGroupDto): Promise<GroupEntity> {
    return Promise.resolve(fixtures);
  }
}
