// Domain
import { LogoutRepository } from '../../../domain/repositories/logout-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class LogoutDummyRepository implements LogoutRepository {
  logout(): Promise<void> {
    return Promise.resolve();
  }
}
